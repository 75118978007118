import Link from 'next/link'
import { useRouter } from 'next/router'
import { getPageName } from 'src/common/Constants'
import { segment } from 'src/lib/Segments'

import Bedrooms from '@components/newListing/filters/bedrooms'
import PriceSelect from '@components/newListing/filters/priceSelect'
import ListingSearch from '@components/newListing/search'
import { formatCount } from '@utility/Utilities'

import { ActionButton } from '..'

type props = {
  listingsDataCount?: number
  listingsLoad?: boolean
  hideTitle?: boolean
}

function RentApartment({
  listingsLoad = false,
  listingsDataCount = null,
  hideTitle = false,
}: props) {
  const router = useRouter()

  return (
    <div className="pt-6 text-center">
      {!hideTitle && (
        <div className="pb-4 text-xl font-semibold text-blue-500">
          Start your search and get access to highly-reviewed apartments
        </div>
      )}
      <div className="mx-auto max-w-lg">
        <ListingSearch />
        <div className="mt-4">
          <PriceSelect />
        </div>
        <div className="my-4">
          <Bedrooms highlightColor="!bg-[#8380A9] text-white" />
        </div>
        <Link href="/listings?showOnboarding=true">
          <ActionButton
            label={
              listingsDataCount
                ? `Search ${formatCount(listingsDataCount)} listings`
                : `Search listings`
            }
            size="none"
            customStyle="sm:py-4 sm:px-8 text-sm"
            isLoading={listingsLoad}
            disabled={listingsLoad}
            onClick={() => {
              segment?.header_listings_search(getPageName(router.asPath.split('/')[1]))
            }}
          />
        </Link>
      </div>
    </div>
  )
}

export default RentApartment
