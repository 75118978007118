import Image from 'next/image'
import { useEffect, useState } from 'react'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { GrClose } from 'react-icons/gr'
import { IoCloseOutline } from 'react-icons/io5'
import { segment } from 'src/lib/Segments'

import { OIModal } from '@components/UI/organisms'
import ActionButton from '@components/buttons/actionButton'
import emptymap from '@public/emptymap.svg'
import { GoogleMap, Polygon, useLoadScript } from '@react-google-maps/api'
import { useAppSelector } from '@redux/hooks'

const GOOGLE_PLACES_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY

const mapContainerStyle = {
  height: 'calc(100vh - 212px)',
  width: '100%',
  borderRadius: '16px',
  overflow: 'hidden',
}

const mapContainerStyleMobile = {
  height: 'calc(100vh - 256px)',
  width: '100%',
  overflow: 'hidden',
}

const center = {
  lat: 40.754159399499386,
  lng: -73.95362145902037,
}

interface PolygonData {
  id: string
  name: string
  paths: { lat: number; lng: number }[]
  children?: PolygonData[]
}

type Props = {
  setNbrModal: (val: boolean) => void
  applyFilter: () => void
  reset: () => void
  updateNeighborhoods: (id: string, name: string, borough: string) => void
  selectedNeighborhoods: any[]
  updateBorough: (name: string) => void
  displayNbr: any
  groupedBoroughs: any
}

function NbrModal({
  setNbrModal,
  applyFilter,
  reset,
  updateNeighborhoods,
  selectedNeighborhoods,
  updateBorough,
  displayNbr,
  groupedBoroughs,
}: Props) {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(null)
  const [activePolygon, setActivePolygon] = useState<PolygonData | null>(null)
  const { neighborhoods } = useAppSelector((state) => state.newSearch)
  const [transformedPolygons, setTransformedPolygons] = useState<PolygonData[]>([])

  const transformPolygonData = (data: any[]): PolygonData[] => {
    const transformed: PolygonData[] = []
    const parentMap = new Map<string, PolygonData>()

    // Helper function to recursively add children
    const addChildren = (parent: PolygonData) => {
      const children = data.filter((polygon) => polygon.parentId === parent.id)

      children.forEach((child) => {
        const childData: PolygonData = {
          id: child.id,
          name: child.name,
          paths: [],
        }

        if (child.mapData && child.mapData.polygon) {
          childData.paths = child.mapData.polygon.map(([lng, lat]: [number, number]) => ({
            lat,
            lng,
          }))
        } else {
          console.warn(`Polygon data is missing or malformed for polygon ID: ${child.id}`)
          return // Skip this child if data is incomplete
        }

        if (!parent.children) {
          parent.children = []
        }

        parent.children.push(childData)
        addChildren(childData) // Recursively add children for this child
      })
    }

    // First pass: Create top-level parents and add them to transformed array
    data.forEach((polygon) => {
      if (!polygon.parentId) {
        const parentData: PolygonData = {
          id: polygon.id,
          name: polygon.name,
          paths: [],
        }

        if (polygon.mapData && polygon.mapData.polygon) {
          parentData.paths = polygon.mapData.polygon.map(([lng, lat]: [number, number]) => ({
            lat,
            lng,
          }))
        } else {
          console.warn(`Polygon data is missing or malformed for polygon ID: ${polygon.id}`)
          return // Skip this parent if data is incomplete
        }

        transformed.push(parentData)
        parentMap.set(polygon.id, parentData) // Add parent to parentMap

        addChildren(parentData) // Recursively add children for this parent
      }
    })

    return transformed
  }

  const calculatePolygonCenter = (paths): { lat: number; lng: number } => {
    let lat = 0,
      lng = 0
    paths.forEach((point) => {
      lat += point.lat
      lng += point.lng
    })
    lat /= paths.length
    lng /= paths.length
    return { lat, lng }
  }

  useEffect(() => {
    const transformedData = transformPolygonData(neighborhoods)
    setTransformedPolygons(transformedData)
  }, [neighborhoods])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_PLACES_API_KEY || '',
    libraries: ['places'],
    language: 'en-GB',
  })

  if (!isLoaded) return <div>Loading...</div>

  const handlePolygonClick = (polygon: PolygonData) => {
    segment?.listings_filter_allneighborhoods_map_neighborhood()
    if (infoWindow) {
      infoWindow.close()
    }
    setActivePolygon(polygon)

    const center = calculatePolygonCenter(polygon.paths)

    const newInfoWindow = new google.maps.InfoWindow({
      content: `
        <div class="flex space-x-8 items-start">
          <div>
            <div class="text-dark-900 text-sm font-semibold !font-['Nunito']">
              ${polygon.name}
            </div>
            <div class="mt-2 cursor-pointer w-fit text-bluegray-300 text-sm underline !font-['Nunito']" id="select-button-${
              polygon.id
            }">
              ${selectedNeighborhoods.some((p) => p.id === polygon.id) ? 'Unselect' : 'Select'}
            </div>
          </div>
          <div id="close-button-${
            polygon.id
          }" class="-mt-1.5 cursor-pointer w-fit !font-['Nunito'] text-lg">x</div>
        </div>
      `,
      position: center,
    })

    newInfoWindow.addListener('domready', () => {
      document.getElementById(`select-button-${polygon.id}`)?.addEventListener('click', () => {
        selectedNeighborhoods.some((p) => p.id === polygon.id)
          ? segment?.listings_filter_allneighborhoods_map_unselect()
          : segment?.listings_filter_allneighborhoods_map_select()
        handlePolygonSelection(polygon)
        newInfoWindow.close()
        setInfoWindow(null)
        setActivePolygon(null)
      })

      document.getElementById(`close-button-${polygon.id}`)?.addEventListener('click', () => {
        segment?.listings_filter_allneighborhoods_map_cardclose()
        newInfoWindow.close()
        setInfoWindow(null)
        setActivePolygon(null)
      })
    })

    newInfoWindow.open(map)
    setInfoWindow(newInfoWindow)
  }

  const handlePolygonSelection = (polygon: any) => {
    updateNeighborhoods(polygon.id, polygon.name, polygon.borough)
  }

  return (
    <OIModal
      onClose={() => setNbrModal(false)}
      customModalStyle="max-w-5xl w-full"
      customHeader={
        <div className="flex w-full items-baseline justify-between space-x-4 bg-white px-8 py-6 text-xl font-semibold text-dark-900">
          <div className="flex-1 flex flex-col">
            <span className="text-center md:text-left">All Neighborhoods</span>
          </div>
          <GrClose
            className="cursor-pointer"
            color="#6764A1"
            size={16}
            onClick={() => {
              segment?.listings_filter_allneighborhoods_map_goback()
              applyFilter()
              setNbrModal(false)
            }}
          />
        </div>
      }
      closeOnEscape={false}
      closeOnOutsideClick={false}
    >
      <div className="flex h-full flex-col overflow-hidden sm:px-8">
        <div className="flex-1 relative overflow-auto">
          <div className="gap-8 sm:grid sm:grid-cols-2">
            <div className="absolute !bottom-0 z-[400] h-[calc(100vh-580px)] w-full overflow-y-auto rounded-t-2xl !bg-white py-4 px-4 sm:relative sm:h-full sm:py-2 sm:px-0">
              <div className="text-xs font-semibold text-mid-400">YOU SELECTED:</div>
              {selectedNeighborhoods.length > 0 && (
                <div className="flex flex-wrap py-4">
                  {Object.entries(displayNbr).map(([key, value], index) =>
                    (value as any[]).length === groupedBoroughs[key]?.length ? (
                      <div
                        key={index}
                        className="no-wrap mr-2 mb-2 flex items-center justify-between space-x-2 rounded-full border border-blue-75 bg-blue-25 py-1 px-3"
                      >
                        <div className="text-xs text-blue-200">{key}</div>
                        <button
                          onClick={() => {
                            segment?.listings_filter_allneighborhoods_map_unselect()
                            updateBorough(key)
                          }}
                        >
                          <IoCloseOutline size={18} color="#393375" />
                        </button>
                      </div>
                    ) : (
                      <>
                        {/* @ts-expect-error type */}
                        {value.map((x) => (
                          <div
                            key={x.id}
                            className="no-wrap mr-2 mb-2 flex items-center justify-between space-x-2 rounded-full border border-blue-75 bg-blue-25 py-1 px-3"
                          >
                            <div className="text-xs text-blue-200">{x.name}</div>
                            <button
                              onClick={() => {
                                segment?.listings_filter_allneighborhoods_map_unselect()
                                updateNeighborhoods(x.id, x.name, x.borough)
                              }}
                            >
                              <IoCloseOutline size={18} color="#393375" />
                            </button>
                          </div>
                        ))}
                      </>
                    )
                  )}
                </div>
              )}
              {selectedNeighborhoods?.length === 0 && (
                <div className="mt-4 text-center">
                  <Image src={emptymap} alt="emptymap" unoptimized />
                  <div className="pt-2 text-sm text-dark-700">
                    Select neighborhoods on the map and explore listings in all of your favorite
                    areas.
                  </div>
                </div>
              )}
            </div>
            <div style={isMobile ? mapContainerStyleMobile : mapContainerStyle}>
              <GoogleMap
                mapContainerStyle={isMobile ? mapContainerStyleMobile : mapContainerStyle}
                zoom={12}
                center={center}
                onLoad={(map) => setMap(map)}
                options={{
                  clickableIcons: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                }}
              >
                {transformedPolygons.map((polygon) => (
                  <React.Fragment key={polygon.id}>
                    {/* Render parent polygon */}
                    <Polygon
                      paths={polygon.paths}
                      options={{
                        fillColor: selectedNeighborhoods.some((x) => x.id === polygon.id)
                          ? '#6764A1B2'
                          : activePolygon?.id === polygon.id
                          ? '#6764A14D'
                          : 'none',
                        fillOpacity:
                          activePolygon?.id === polygon.id ||
                          selectedNeighborhoods.some((x) => x.id === polygon.id)
                            ? 1
                            : 0,
                        strokeColor: '#6764A1',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                      onClick={() => handlePolygonClick(polygon)}
                    />
                    {/* Render child polygons */}
                    {polygon.children?.map((child) => (
                      <React.Fragment key={child.id}>
                        <Polygon
                          paths={child.paths}
                          options={{
                            fillColor: selectedNeighborhoods.some((x) => x.id === child.id)
                              ? '#6764A1B2'
                              : activePolygon?.id === child.id
                              ? '#6764A14D'
                              : 'none',
                            fillOpacity:
                              activePolygon?.id === child.id ||
                              selectedNeighborhoods.some((x) => x.id === child.id)
                                ? 1
                                : 0,
                            strokeColor: '#6764A1',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                          }}
                          onClick={() => handlePolygonClick(child)}
                        />
                        {/* Render grandchild polygons */}
                        {child.children?.map((grandchild) => (
                          <Polygon
                            key={grandchild.id}
                            paths={grandchild.paths}
                            options={{
                              fillColor: selectedNeighborhoods.some((x) => x.id === grandchild.id)
                                ? '#6764A1B2'
                                : activePolygon?.id === grandchild.id
                                ? '#6764A14D'
                                : 'none',
                              fillOpacity:
                                activePolygon?.id === grandchild.id ||
                                selectedNeighborhoods.some((x) => x.id === grandchild.id)
                                  ? 1
                                  : 0,
                              strokeColor: '#6764A1',
                              strokeOpacity: 0.8,
                              strokeWeight: 2,
                            }}
                            onClick={() => handlePolygonClick(grandchild)}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </GoogleMap>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-6 py-4 px-4 sm:px-0">
          <div
            className="cursor-pointer text-sm text-bluegray-300 underline"
            onClick={() => {
              segment?.listings_filter_allneighborhoods_map_resetselections()
              reset()
            }}
          >
            Reset
          </div>
          <ActionButton
            label={'Done'}
            size="none"
            customStyle="text-sm px-4 py-1.5"
            onClick={() => {
              segment?.listings_filter_allneighborhoods_map_seeresults()
              applyFilter()
              setNbrModal(false)
            }}
          />
        </div>
      </div>
    </OIModal>
  )
}

export default NbrModal
