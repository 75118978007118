import { groupBy } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FiMap } from 'react-icons/fi'
import { GrClose, GrFormClose } from 'react-icons/gr'
import { HiOutlineArrowUpRight } from 'react-icons/hi2'
import { IoCheckmarkCircleOutline, IoCloseOutline, IoSearchOutline } from 'react-icons/io5'
import { BeatLoader } from 'react-spinners'
import { AutocompleteSuggestion } from 'src/common/types'
import { getSearchNycAutoCompleteApi } from 'src/data/Buildings'
import { segment } from 'src/lib/Segments'
import { v4 as uuidv4 } from 'uuid'

import HighlightText from '@components/Search/HighlightText'
import { OIModal } from '@components/UI/organisms'
import { getNewNeighborhood, getTrendingNeighborhood } from '@redux/actions/newSearch'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { newSearchSlice } from '@redux/reducers/newSearch'
import { titleCase } from '@utility/Utilities'

import { ActionButton } from '..'
import NbrModal from './nbrModal'

type Props = {
  placeholder?: string
}

function ListingSearch({ placeholder = 'Search neighborhoods or addresses' }: Props) {
  const dispatch = useThunkDispatch()
  const { neighborhoods, trendingNeighborhoods } = useAppSelector((state) => state.newSearch)

  const [searchedValue, setSearchedValue] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [groupedBoroughs, setGroupedBoroughs] = useState(null)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [searchedNeighborhoods, setSearchedNeighborhoods] = useState({})
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([])
  const [selectedBoroughs, setSelectedBoroughs] = useState([])
  const [displayNbr, setDisplayNbr] = useState({})
  const [displayPlaceholder, setDisplayPlaceholder] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [nbrModal, setNbrModal] = useState(false)
  const { searchFilter } = useAppSelector((state) => state.newSearch)
  const [value, setValue] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [suggestions, setSuggestions] = useState<(AutocompleteSuggestion | null)[]>([])
  const [, setActiveRequests] = useState(0)
  const [searchToken] = useState(uuidv4())

  const inputRef = useRef(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    if (value.length > 3) {
      setIsSearching(true)
      const timeoutId = setTimeout(() => {
        incrementActiveRequests()
        getAutoComplete(value)
      }, 1000)

      return () => clearTimeout(timeoutId)
    }
  }, [value])

  function incrementActiveRequests() {
    setActiveRequests((prev) => prev + 1)
  }

  function decrementActiveRequests() {
    setActiveRequests((prev) => {
      const updated = prev - 1
      if (updated === 0) {
        setIsSearching(false)
      }
      return updated
    })
  }

  async function getAutoComplete(value: string) {
    try {
      const data = await getSearchNycAutoCompleteApi(value, searchToken)
      setSuggestions(data)
    } catch {
      console.log('error')
      setSuggestions([])
    } finally {
      decrementActiveRequests()
    }
  }

  useEffect(() => {
    if (!neighborhoods) {
      // Todo Change api to listing service
      dispatch(getNewNeighborhood())
    }
    if (!trendingNeighborhoods) {
      dispatch(getTrendingNeighborhood())
    }
  }, [])

  useEffect(() => {
    if (neighborhoods?.length > 0) {
      const groupedBrs = groupBy(neighborhoods, function (nbr) {
        return nbr.borough
      })
      delete groupedBrs?.null
      dispatch(newSearchSlice.actions.setGrpNeighborhoods(groupedBrs))
      setGroupedBoroughs(groupedBrs)
    }
  }, [neighborhoods])

  useEffect(() => {
    if (neighborhoods?.length > 0 && searchFilter) {
      if (searchFilter.neighborhoodIds.length > 0) {
        const selectedNbr = []
        searchFilter.neighborhoodIds.map((x) => {
          const nbr = neighborhoods.find((y) => y.id == x)
          if (nbr) {
            const data = { id: x, name: nbr?.name, borough: nbr?.borough }
            selectedNbr.push(data)
          }
        })
        setSelectedNeighborhoods(selectedNbr)
        setSelectedAddress(null)
      } else if (searchFilter.buildingId && searchFilter.buildingName) {
        setSelectedAddress({ id: searchFilter.buildingId, address: searchFilter.buildingName })
      } else {
        reset()
      }
    }
  }, [neighborhoods, searchFilter])

  useEffect(() => {
    if (searchedValue.length > 0) {
      const searchedData = searchNeigborhoods(searchedValue)
      setSearchedNeighborhoods(searchedData)
    }
  }, [searchedValue, groupedBoroughs])

  function searchNeigborhoods(searchTerm: string) {
    const result = {}

    if (groupedBoroughs) {
      for (const key in groupedBoroughs) {
        if (Object.prototype.hasOwnProperty.call(groupedBoroughs, key)) {
          const filteredValues = groupedBoroughs[key].filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          if (filteredValues.length > 0) {
            result[key] = filteredValues
          }
        }
      }
    }
    return result
  }

  function updateNeighborhoods(id: string, name: string, borough: string, avoidSplice?: boolean) {
    const newData = selectedNeighborhoods
    const index = newData.findIndex((x) => x.id == id)
    if (index === -1) {
      newData.push({ id: id, name: name, borough: borough })
    } else {
      if (!avoidSplice) {
        newData.splice(index, 1)
        if (selectedBoroughs.find((x) => x == borough)) {
          updateBorough(borough, true)
        }
      }
    }
    setSelectedNeighborhoods([...newData])
    setSearchedValue('')
    setSelectedAddress(null)
    setValue('')
  }

  function updateBorough(name: string, onlyBorough?: boolean) {
    const newData = selectedBoroughs
    const index = newData.indexOf(name)
    if (index === -1) {
      newData.push(name)
      groupedBoroughs[name].map((x) => {
        updateNeighborhoods(x.id, x.name, x.borough, true)
      })
    } else {
      newData.splice(index, 1)
      if (!onlyBorough) {
        groupedBoroughs[name].map((x) => {
          updateNeighborhoods(x.id, x.name, x.borough)
        })
      }
    }
    setSelectedBoroughs([...newData])
    setSearchedValue('')
    setSelectedAddress(null)
    setValue('')
  }

  useEffect(() => {
    const groupedBrs = groupBy(selectedNeighborhoods, function (nbr) {
      return nbr.borough
    })
    setDisplayNbr(groupedBrs)
  }, [selectedNeighborhoods])

  function checkBoroughSearch() {
    if (groupedBoroughs) {
      const keys = Object?.keys(groupedBoroughs)
      return keys.some((key) => key.toLowerCase().includes(searchedValue.toLowerCase()))
    }
    return false
  }

  useEffect(() => {
    if (!showSuggestions) {
      if (selectedAddress) {
        setDisplayPlaceholder(selectedAddress.address)
      } else if (selectedBoroughs.length > 0 || selectedNeighborhoods.length > 0) {
        let displayText = ''
        const entries = Object.entries(displayNbr)

        entries.forEach(([key, value], index) => {
          /* @ts-expect-error type */
          if (value.length === groupedBoroughs[key]?.length) {
            displayText += key
          } else {
            /* @ts-expect-error type */
            value.forEach((x, i) => {
              displayText += x.name
              /* @ts-expect-error type */
              if (i < value.length - 1) {
                displayText += ', '
              }
            })
          }

          if (index < entries.length - 1) {
            displayText += ', '
          }
        })

        setDisplayPlaceholder(displayText)
      } else {
        setDisplayPlaceholder(null)
      }
    } else {
      setDisplayPlaceholder(null)
    }
  }, [showSuggestions, selectedAddress, selectedNeighborhoods, displayNbr])

  function reset() {
    setSelectedAddress(null)
    setSelectedBoroughs([])
    setSelectedNeighborhoods([])
  }

  function applyFilter() {
    setShowSuggestions(false)
    if (selectedNeighborhoods.length > 0) {
      const nbrIds = []
      selectedNeighborhoods.map((x) => {
        nbrIds.push(x.id)
      })
      dispatch(
        newSearchSlice.actions.setSearchFilter({ neighborhoodIds: nbrIds, buildingId: null })
      )
    } else if (selectedAddress) {
      dispatch(newSearchSlice.actions.resetFilter())
      dispatch(
        newSearchSlice.actions.setSearchFilter({
          neighborhoodIds: [],
          buildingId: selectedAddress.id,
          buildingName: selectedAddress.address,
        })
      )
    } else {
      dispatch(
        newSearchSlice.actions.setSearchFilter({
          neighborhoodIds: [],
          buildingId: null,
          buildingName: null,
        })
      )
    }
  }

  useEffect(() => {
    Object.entries(displayNbr).map(([key, value]) => {
      /* @ts-expect-error type */
      if (!selectedBoroughs.includes(key) && value.length == groupedBoroughs[key]?.length) {
        setSelectedBoroughs((prev) => [...prev, key])
      }
    })
  }, [displayNbr])

  useEffect(() => {
    function adjustDropdownWidth() {
      if (inputRef.current && dropdownRef.current) {
        dropdownRef.current.style.width = `${inputRef.current.offsetWidth}px`
      }
    }

    adjustDropdownWidth()

    window.addEventListener('resize', adjustDropdownWidth)

    return () => {
      window.removeEventListener('resize', adjustDropdownWidth)
    }
  }, [showSuggestions])

  const suggestionsJSX = (
    <div>
      {searchedValue.length > 0 && (
        <div>
          <div className="px-4 text-left text-xs font-semibold text-mid-400">SEARCH RESULTS:</div>
          <div className="px-4 pt-3 pb-2 text-left text-xs text-mid-400">Building:</div>
          {isSearching && suggestions.length == 0 && (
            <div className="px-4 text-left text-sm text-dark-700">
              Keep typing while we look for results...
            </div>
          )}
          {!isSearching && suggestions.length == 0 && searchedValue.length >= 1 && (
            <div className="px-4 text-left text-sm text-dark-700">No buildings found</div>
          )}
          {suggestions.length > 0 && (
            <div className="text-left text-sm text-dark-900">
              {suggestions.map((x) => {
                return (
                  <div
                    key={x.buildingSlug}
                    className={`hover:bg-blue-25 ${
                      selectedAddress?.id == x.buildingSlug && 'bg-blue-25'
                    }`}
                    onClick={() => {
                      segment?.listings_filter_building()
                      setSearchedValue('')
                      setSelectedAddress({ address: x.address, id: x.buildingSlug })
                      setSelectedNeighborhoods([])
                      setSelectedBoroughs([])
                      setValue('')
                    }}
                  >
                    <div className="flex items-center justify-between px-4 py-2">
                      <HighlightText text={x.address} searchTerm={value} />
                      {selectedAddress?.id == x.buildingSlug && (
                        <IoCheckmarkCircleOutline color="#6764A1" size={20} />
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          <div className="text-left">
            {checkBoroughSearch() && (
              <div className="px-4 pt-4 pb-1 text-xs text-mid-400">Borough</div>
            )}
            {groupedBoroughs &&
              Object.entries(groupedBoroughs).map(([key], index) => {
                return (
                  key.toString().toLowerCase().includes(searchedValue.toLowerCase()) && (
                    <div
                      key={index}
                      onClick={() => {
                        segment?.listings_filter_neighborhood_select()
                        updateBorough(key)
                      }}
                      className={`text-sm text-dark-900 hover:bg-blue-25 ${
                        selectedBoroughs.find((x) => x == key) && 'bg-blue-25'
                      }`}
                    >
                      <div className="flex items-center justify-between px-4 py-2">
                        <HighlightText text={key} searchTerm={value} />
                        {selectedBoroughs.find((x) => x == key) && (
                          <IoCheckmarkCircleOutline color="#6764A1" size={20} />
                        )}
                      </div>
                    </div>
                  )
                )
              })}
          </div>
          <div>
            {groupedBoroughs &&
              searchedNeighborhoods &&
              Object.entries(searchedNeighborhoods).map(([key, val], index) => {
                return (
                  <div key={index} className="text-left">
                    <div className="px-4 pt-4 pb-1 text-xs text-mid-400">{key}</div>
                    {/* @ts-expect-error type */}
                    {val.map((y) => {
                      return (
                        <div
                          key={y.id}
                          onClick={() => {
                            segment?.listings_filter_neighborhood_select()
                            updateNeighborhoods(y.id, y.name, y.borough)
                          }}
                          className={`text-sm text-dark-900 hover:bg-blue-25 ${
                            selectedNeighborhoods.find((x) => x.id == y.id) && 'bg-blue-25'
                          }`}
                        >
                          <div className="flex items-center justify-between px-4 py-2">
                            <HighlightText text={y.name} searchTerm={value} />
                            {selectedNeighborhoods.find((x) => x.id == y.id) && (
                              <IoCheckmarkCircleOutline color="#6764A1" size={20} />
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
          </div>
        </div>
      )}
      {selectedNeighborhoods.length > 0 && searchedValue.length == 0 && (
        <div className="flex flex-wrap px-4 pb-4">
          {Object.entries(displayNbr).map(([key, value], index) => {
            /* @ts-expect-error type */
            return value.length == groupedBoroughs[key]?.length ? (
              <div
                key={index}
                className="no-wrap mr-2 mb-2 flex items-center justify-between space-x-2 rounded-full border border-blue-75 bg-blue-25 py-1 px-3"
              >
                <div className="text-xs text-blue-200">{key}</div>
                <button onClick={() => updateBorough(key)}>
                  <IoCloseOutline size={18} color="#393375" />
                </button>
              </div>
            ) : (
              <>
                {/* @ts-expect-error type */}
                {value.map((x) => {
                  return (
                    <div
                      key={x.id}
                      className="no-wrap mr-2 mb-2 flex items-center justify-between space-x-2 rounded-full border border-blue-75 bg-blue-25 py-1 px-3"
                    >
                      <div className="text-xs text-blue-200">{x.name}</div>
                      <button onClick={() => updateNeighborhoods(x.id, x.name, x.borough)}>
                        <IoCloseOutline size={18} color="#393375" />
                      </button>
                    </div>
                  )
                })}
              </>
            )
          })}
        </div>
      )}
      {selectedAddress && searchedValue.length == 0 && (
        <div className="no-wrap mx-4 mb-4 mr-2 mb-2 flex w-fit items-center justify-between space-x-2 rounded-full border border-blue-75 bg-blue-25 py-1 px-3">
          <div className="text-xs text-blue-200">{selectedAddress.address}</div>
          <button onClick={() => setSelectedAddress(null)}>
            <IoCloseOutline size={18} color="#393375" />
          </button>
        </div>
      )}
      {searchedValue.length == 0 && trendingNeighborhoods?.length > 0 && (
        <div className="text-left">
          {trendingNeighborhoods.some(
            (trending) => !selectedNeighborhoods.some((selected) => selected.id === trending.id)
          ) && (
            <>
              <div className="px-4 pb-4 text-xs font-semibold text-mid-400">TRENDING SEARCHES:</div>
              <div className="grid grid-cols-2 gap-4 px-4">
                {trendingNeighborhoods.map((x) => {
                  return selectedNeighborhoods.findIndex((y) => y.id == x.id) == -1 ? (
                    <div
                      className="flex w-fit cursor-pointer items-center space-x-2"
                      key={x.id}
                      onClick={() => {
                        segment?.listings_filter_neighborhood_trending()
                        updateNeighborhoods(x.id, x.name, x.borough)
                      }}
                    >
                      <HiOutlineArrowUpRight color="#6764A1" size={16} />
                      <div className="text-sm text-dark-700">{x.name}</div>
                    </div>
                  ) : null
                })}
              </div>
            </>
          )}
          {neighborhoods?.length > 0 && (
            <div
              onClick={() => {
                segment?.listings_filter_allneighborhoods_map_seeall()
                setNbrModal(true)
                setShowModal(false)
              }}
              className="mx-4 mt-6 mb-2 flex cursor-pointer items-center justify-center space-x-4 rounded-lg bg-light-10 p-2 shadow"
            >
              <div className="text-sm text-bluegray-300">See all neighborhoods</div>
              <FiMap color="#6764A1" />
            </div>
          )}
        </div>
      )}
    </div>
  )

  const inputJSX = (
    <div
      ref={inputRef}
      className="flex items-center space-x-2 rounded-full border border-light-40 bg-white py-2 px-4"
    >
      <IoSearchOutline />
      <input
        placeholder={displayPlaceholder ? displayPlaceholder : placeholder}
        className={`w-full text-left placeholder:text-sm focus:outline-none ${
          displayPlaceholder && 'placeholder:text-dark-900'
        }`}
        onChange={(e) => {
          setValue(titleCase(e.target.value))
          setSearchedValue(titleCase(e.target.value))
          setShowSuggestions(true)
        }}
        onClick={() => {
          segment?.listings_filter_neighborhood_open()
        }}
        onFocus={() => {
          setShowSuggestions(true)
        }}
        // disabled={!neighborhoods || neighborhoods?.length == 0}
        value={searchedValue}
      />
      <div className="flex w-[30px] items-center sm:w-[25px]">
        {isSearching && searchedValue.length > 0 ? (
          <BeatLoader size={3} color="#000000" />
        ) : (
          searchedValue && (
            <button
              aria-label="clear selection"
              onClick={() => {
                setValue('')
                setSearchedValue('')
              }}
            >
              <GrFormClose size={20} />
            </button>
          )
        )}
      </div>
    </div>
  )

  const searchJsx = (
    <div>
      {inputJSX}
      {showSuggestions && (
        <div
          ref={dropdownRef}
          className="absolute z-50 max-h-[400px] rounded-2xl bg-white py-4 shadow-lg"
        >
          <div className="max-h-[320px] overflow-y-auto">
            <div>{suggestionsJSX}</div>
          </div>
          {searchedValue.length == 0 && (
            <div className="flex items-center justify-end space-x-6 px-4 pt-4">
              <div
                className="cursor-pointer text-sm text-bluegray-300 underline"
                onClick={() => {
                  segment?.listings_filter_neighborhood_reset()
                  reset()
                }}
              >
                Reset
              </div>
              <ActionButton
                label={'Done'}
                size="none"
                customStyle="text-sm px-6 py-2"
                onClick={() => {
                  segment?.listings_filter_neighborhood_seeresults()
                  applyFilter()
                  setShowModal(false)
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )

  const modalJsx = (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="px-4">{inputJSX}</div>
      <div className="flex-1 overflow-auto py-4">{suggestionsJSX}</div>
      {searchedValue.length == 0 && (
        <div className="flex items-center justify-end space-x-6 py-2 px-4">
          <div
            className="cursor-pointer text-sm text-bluegray-300 underline"
            onClick={() => reset()}
          >
            Reset
          </div>
          <ActionButton
            label={'Done'}
            size="none"
            customStyle="text-sm px-4 py-1.5"
            onClick={() => {
              segment?.listings_filter_neighborhood_seeresults()
              applyFilter()
              setShowModal(false)
            }}
          />
        </div>
      )}
    </div>
  )

  const CustomHeader = (
    <div className="flex w-full items-baseline justify-between space-x-4 bg-white px-6 py-6 text-xl font-semibold text-dark-900">
      <div className="flex-1 flex flex-col">
        <span className="text-center md:text-left">Neighborhood & Address</span>
      </div>
      <GrClose
        className="cursor-pointer"
        color="#6764A1"
        size={16}
        onClick={() => {
          applyFilter()
          setShowModal(false)
        }}
      />
    </div>
  )

  return (
    <div>
      {isMobile ? (
        <div>
          <div className="flex items-center space-x-2 rounded-full border border-light-40 bg-white py-2.5 px-4">
            <IoSearchOutline />
            <div
              className={`w-full truncate text-left text-sm focus:outline-none ${
                displayPlaceholder && 'text-dark-900'
              }`}
              onClick={() => {
                setShowModal(true)
              }}
            >
              {displayPlaceholder ? displayPlaceholder : placeholder}
            </div>
          </div>
          {showModal && (
            <OIModal
              onClose={() => {
                setShowModal(false)
              }}
              customModalStyle="!max-w-xl w-full"
              customHeader={CustomHeader}
              closeOnEscape={false}
              closeOnOutsideClick={false}
            >
              {modalJsx}
            </OIModal>
          )}
        </div>
      ) : (
        <div>{searchJsx}</div>
      )}
      {nbrModal && (
        <NbrModal
          setNbrModal={setNbrModal}
          applyFilter={applyFilter}
          reset={reset}
          updateNeighborhoods={updateNeighborhoods}
          selectedNeighborhoods={selectedNeighborhoods}
          updateBorough={updateBorough}
          displayNbr={displayNbr}
          groupedBoroughs={groupedBoroughs}
        />
      )}
    </div>
  )
}

export default ListingSearch
