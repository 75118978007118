import { useEffect, useState } from 'react'
import { segment } from 'src/lib/Segments'

import CustomSelect from '@components/generic/CustomSelect'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { newSearchSlice } from '@redux/reducers/newSearch'
import { currency } from '@utility/Utilities'

function PriceSelect() {
  const { filters } = useAppSelector((state) => state.newSearch)
  const [minRent, setMinRent] = useState(filters.minRent)
  const [maxRent, setMaxRent] = useState(filters.maxRent)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    setMinRent(filters.minRent)
    setMaxRent(filters.maxRent)
  }, [filters])

  const minoptions = [{ label: 'No min', value: null }]
  const maxoptions = []

  for (let i = 1000; i <= 10000; i += 500) {
    minoptions.push({ label: currency(i), value: i })
  }

  for (let i = 1000; i <= 10000; i += 500) {
    maxoptions.push({ label: currency(i), value: i })
  }

  maxoptions.push({ label: 'No max', value: null })

  return (
    <div className="flex w-full space-x-2 pb-1 text-left">
      <div className="w-full">
        <div className="pb-2 font-semibold text-dark-900">Min Price</div>
        <CustomSelect
          customCss={'w-full text-sm text-dark-900'}
          placeholder={'Select a min budget'}
          value={{
            value: minoptions?.find((x) => x.value === minRent)?.value ?? null,
            label: minoptions.find((x) => x.value === minRent)?.label ?? 'No min',
          }}
          options={minoptions}
          isSearchable={false}
          onChange={(item: any) => {
            setMinRent(item.value)
            if (item.value >= maxRent) {
              dispatch(newSearchSlice.actions.setFilter({ minRent: item.value, maxRent: null }))
              segment?.listings_filter_price_min(item.value)
              segment?.listings_filter_price_max(null)
            } else {
              dispatch(newSearchSlice.actions.setFilter({ minRent: item.value }))
              segment?.listings_filter_price_min(item.value)
            }
          }}
        />
      </div>
      <div className="w-full">
        <div className="pb-2 font-semibold text-dark-900">Max Price</div>
        <CustomSelect
          customCss={'w-full text-sm text-dark-900'}
          placeholder={'Select a max budget'}
          value={{
            value: maxoptions?.find((x) => x.value === maxRent)?.value ?? null,
            label: maxoptions.find((x) => x.value === maxRent)?.label ?? 'No max',
          }}
          options={maxoptions}
          isSearchable={false}
          onChange={(item: any) => {
            setMaxRent(item.value)
            if (item.value <= minRent) {
              dispatch(newSearchSlice.actions.setFilter({ maxRent: item.value, minRent: null }))
              segment?.listings_filter_price_min(null)
              segment?.listings_filter_price_max(item.value)
            } else {
              dispatch(newSearchSlice.actions.setFilter({ maxRent: item.value }))
              segment?.listings_filter_price_max(item.value)
            }
          }}
        />
      </div>
    </div>
  )
}

export default PriceSelect
