import { useEffect, useState } from 'react'
import { segment } from 'src/lib/Segments'

import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { newSearchSlice } from '@redux/reducers/newSearch'

type props = {
  highlightColor?: string
}

function Bedrooms({ highlightColor = '!bg-blue-25' }: props) {
  const { filters } = useAppSelector((state) => state.newSearch)
  const [selectedBedroom, setSelectedBedroom] = useState(filters.numBedrooms)
  const dispatch = useThunkDispatch()

  useEffect(() => {
    setSelectedBedroom(filters.numBedrooms)
  }, [filters])

  function updateBedrooms(bd: number) {
    const newData = [...selectedBedroom]
    const index = newData.indexOf(bd)
    if (index === -1) {
      newData.push(bd)
    } else {
      newData.splice(index, 1)
    }
    setSelectedBedroom([...newData])
    segment?.listings_filter_beds(newData)
    dispatch(newSearchSlice.actions.setFilter({ numBedrooms: [...newData] }))
  }

  return (
    <div className="text-left">
      <div className="pb-2 font-semibold text-dark-900">Bedrooms</div>
      <div className="flex w-full space-x-2">
        <div
          onClick={() => updateBedrooms(0)}
          className={`w-full cursor-pointer rounded-lg border border-light-40 bg-white py-2 px-2 text-center text-sm text-dark-900 sm:px-10 
            ${selectedBedroom.includes(0) && highlightColor}`}
        >
          Studio
        </div>
        <div
          onClick={() => updateBedrooms(1)}
          className={`w-full cursor-pointer rounded-lg border border-light-40 bg-white py-2 text-center text-sm text-dark-900 sm:px-6
            ${selectedBedroom.includes(1) && highlightColor}`}
        >
          1
        </div>
        <div
          onClick={() => updateBedrooms(2)}
          className={`w-full cursor-pointer rounded-lg border border-light-40 bg-white py-2 text-center text-sm text-dark-900 sm:px-6
          ${selectedBedroom.includes(2) && highlightColor}`}
        >
          2
        </div>
        <div
          onClick={() => updateBedrooms(3)}
          className={`w-full cursor-pointer rounded-lg border border-light-40 bg-white py-2 text-center text-sm text-dark-900 sm:px-6
          ${selectedBedroom.includes(3) && highlightColor}`}
        >
          3
        </div>
        <div
          onClick={() => updateBedrooms(4)}
          className={`w-full cursor-pointer rounded-lg border border-light-40 bg-white py-2 text-center text-sm text-dark-900 sm:px-6
          ${selectedBedroom.includes(4) && highlightColor}`}
        >
          4+
        </div>
      </div>
    </div>
  )
}

export default Bedrooms
